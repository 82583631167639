import(/* webpackMode: "eager" */ "/build/38815ff8-72e9-40ea-9e0e-a4591082ada3/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/build/38815ff8-72e9-40ea-9e0e-a4591082ada3/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/build/38815ff8-72e9-40ea-9e0e-a4591082ada3/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/build/38815ff8-72e9-40ea-9e0e-a4591082ada3/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/build/38815ff8-72e9-40ea-9e0e-a4591082ada3/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/build/38815ff8-72e9-40ea-9e0e-a4591082ada3/node_modules/next/dist/client/components/metadata/async-metadata.js");
;
import(/* webpackMode: "eager" */ "/build/38815ff8-72e9-40ea-9e0e-a4591082ada3/node_modules/next/dist/client/components/metadata/metadata-boundary.js");
;
import(/* webpackMode: "eager" */ "/build/38815ff8-72e9-40ea-9e0e-a4591082ada3/node_modules/next/dist/client/components/render-from-template-context.js");
